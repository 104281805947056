'use client';

import { Box, Button, Flex, Text } from '@chakra-ui/react';
import DoctorImage from '@public/doctor.png';
import Image from 'next/image';

import { tokens } from '@/styles/tokens';
const loginUrl = () =>
  `${process.env.NEXT_PUBLIC_ORY_SDK_URL}/self-service/login/browser?return_to=${window.location.origin}`;

export default function LoginPage() {
  const redirectToLogin = () => {
    window.location.href = loginUrl();
  };

  return (
    <>
      <Flex justifyContent='center' alignItems='center' w='100%' h='100%'>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          flexGrow={1}
        >
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            w='50%'
          >
            <Box marginBottom={2}>
              <Image
                src={`${process.env.NEXT_PUBLIC_STATIC_URL}/doctor-onboarding/medsimples-logo.svg`}
                alt='logo'
                width={400}
                height={400}
              />
            </Box>

            <Text fontSize='3xl' marginBottom={7}>
              Cadastro Médico
            </Text>
            <Text marginBottom={3}>Cadastro médico rápido e fácil?</Text>
            <Text marginBottom={12}>
              Experimente o jeito{' '}
              <Text
                as='span'
                color={tokens.text.primary.color}
                fontWeight={700}
                fontSize='xl'
              >
                Med
              </Text>
              <Text
                as='span'
                color={tokens.text.highlight.color}
                fontWeight={700}
                fontSize='xl'
              >
                Simples
              </Text>
              :
            </Text>

            <Box w='100%'>
              <Button w='100%' colorScheme='teal' onClick={redirectToLogin}>
                Entrar
              </Button>
            </Box>
          </Flex>
        </Flex>
        <Box maxH='100vh'>
          <Image src={DoctorImage} alt='Imagem doutor' />
        </Box>
      </Flex>
    </>
  );
}
